<template>
<!-- 
  Wenn das Modul überarbeitet wird, müssen die Ankommenden Daten zuerst mir Andi zusammen überarbeitet werden, damit alles schön und übersichtlich wird, besser nicht versuchen den code zu verstehen ;)
 -->
  <div>
    <div v-for="(group, i) in element.value" :key="i">
      <div v-for="(chart, name, ii) in group" :key="ii" class="d-flex flex-nowrap align-center">
        <div style="width: 100px">{{ $t(name) }}</div><apexchart :options="options" :series="formatData(chart)" height="180px" class="flex-grow-1"/>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "xdslCharts",
  components: { apexchart: VueApexCharts },
  props: {
    element: {
      type: Object,
      required: false
    }
  },
  data: function() {
    return {
      someData: null
    };
  },
  computed: {
    options() {
      this.$vuetify.theme.dark; // force re-compute
      
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        legend: {
          showForSingleSeries: true
        },

        chart: {
          background: this.$vuetify.theme.dark ? "#1E1E1E" : "#FFF",
          animations: {
            enabled: false
          },
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
            tools: {
              download: false
            }
          }
        },
        stroke: {
          curve: "smooth",
          width: 2
        },
        xaxis: {
          type: "datetime"
        }
      };
    }
  },
  methods: {
    formatData(set) {
      // console.log(set);
      var new_set = [];

      var down = [];
      var up = [];
      var resyncs = [];

      for (let inner = 0; inner < set.length; inner++) {
        var element = set[inner];

        if (element.down !== undefined) {
          down.push([new Date(element.date), element.down]);
        }
        if (element.up !== undefined) {
          up.push([new Date(element.date), element.up]);
        }
        if (element.resyncs !== undefined) {
          resyncs.push([new Date(element.date), element.resyncs]);
        }
      }

      if (down.length > 0) {
        new_set.push({ data: down, name: "down" });
      }
      if (up.length > 0) {
        new_set.push({ data: up, name: "up" });
      }
      if (resyncs.length > 0) {
        new_set.push({ data: resyncs, name: "resyncs" });
      }

      // console.log(new_set);
      return new_set;
    }
  },
};
</script>

<i18n>
{
  "en": {
    "no data": "No data",
    "coding_violations":"Coding violations",
    "noise_margin":"Noise margin",
    "severe_error_seconds":"Severe error seconds",
    "spontaneous_resyncs":"Spontaneous resyncs"
  },
  "de": {
    "no data": "Keine Daten",
    "coding_violations":"Coding violations",
    "noise_margin":"Noise margin",
    "severe_error_seconds":"Severe error seconds",
    "spontaneous_resyncs":"Spontaneous resyncs"
  }
}
</i18n>
