var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.service.dn))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading || !_vm.hasProductRole,"icon":""},on:{"click":function($event){return _vm.$router.push({
              name: 'product-details',
              params: { productId: _vm.bbcsProductId },
            })}}},on),[_c('v-icon',[_vm._v("mdi-text-box")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("open product")))])]),(_vm.$store.state.session.partnerType == 'WHO')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.$store.getters.isReadOnly || !_vm.hasOrderRole,"icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'internet-order' })}}},on),[_c('v-icon',[_vm._v("mdi-plus-network-outline")])],1)]}}],null,false,3960914714)},[_c('span',[_vm._v(_vm._s(_vm.$t("add new internet service")))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading || _vm.xDslInfoDialog,"icon":""},on:{"click":function($event){_vm.xDslInfoDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-help-network-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("xDSL status information")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading ||
            _vm.ticketDialog ||
            _vm.profile === null ||
            !_vm.canTicket ||
            _vm.$store.getters.isReadOnly,"icon":""},on:{"click":function($event){_vm.ticketDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-progress-wrench")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("create BBCS ticket")))])]),_c('wiki',{attrs:{"slug":"services-bbcs-details"}})],1),_c('v-dialog',{attrs:{"width":"80%","scrollable":"","persistent":""},model:{value:(_vm.ticketDialog),callback:function ($$v) {_vm.ticketDialog=$$v},expression:"ticketDialog"}},[(_vm.profile)?_c('bbcs-ticket',{attrs:{"profile":_vm.profile,"product-id":_vm.bbcsProductId},on:{"ticketCanceled":function($event){_vm.ticketDialog = false},"ticketCreated":function($event){_vm.ticketDialog = false;
        _vm.getWorkers();}}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"80%","scrollable":"","persistent":""},model:{value:(_vm.xDslInfoDialog),callback:function ($$v) {_vm.xDslInfoDialog=$$v},expression:"xDslInfoDialog"}},[(_vm.bbcsProductId && _vm.xDslInfoDialog)?_c('bbcs-xdsl-info',{attrs:{"product-id":_vm.bbcsProductId},on:{"close":function($event){_vm.xDslInfoDialog = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"80%","scrollable":"","persistent":""},model:{value:(_vm.upgradeDialog),callback:function ($$v) {_vm.upgradeDialog=$$v},expression:"upgradeDialog"}},[(_vm.upgradeDialog)?_c('bbcs-upgrade',{attrs:{"product":_vm.product,"bbcs-profile":_vm.profile,"bbcs-service":_vm.service,"bbcs-service-status":_vm.serviceStatus},on:{"upgradeCanceled":function($event){_vm.upgradeDialog = false},"upgradeOrdered":function($event){_vm.upgradeDialog = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"80%","scrollable":""},model:{value:(_vm.ipSetupDialog),callback:function ($$v) {_vm.ipSetupDialog=$$v},expression:"ipSetupDialog"}},[(_vm.ipSetupDialog)?_c('ip-setup',{attrs:{"product":_vm.product},on:{"close":function($event){_vm.ipSetupDialog = false}}}):_vm._e()],1),_c('v-card-text',[(_vm.profile)?_c('v-card',{staticClass:"mb-4",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.profile.product_type_name))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading ||
                _vm.upgradeDialog ||
                _vm.profile === null ||
                !_vm.canOrder ||
                _vm.$store.getters.isReadOnly ||
                !_vm.hasOrderRole,"icon":""},on:{"click":function($event){_vm.upgradeDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-tune")])],1)]}}],null,false,1077997738)},[_c('span',[_vm._v(_vm._s(_vm.$t("up/downgrade line")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading || !_vm.isPpp,"icon":""},on:{"click":function($event){_vm.ipSetupDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-ip")])],1)]}}],null,false,376977664)},[_c('span',[_vm._v(_vm._s(_vm.$t("open IP setup")))])])],1),_c('v-card-text',[_c('product-attributes',{attrs:{"attributes":_vm.profile.attributes}})],1)],1):_vm._e(),(_vm.bbcsProductId)?_c('device-info',{staticClass:"mb-4",attrs:{"product-id":_vm.bbcsProductId}}):_vm._e(),(_vm.bbcsProductId)?_c('bbcs-service-status',{staticClass:"mb-4",attrs:{"status":_vm.serviceStatus,"loading":_vm.loadingServiceStatus},on:{"updateStatus":_vm.getServiceStatus}}):_vm._e(),(_vm.bbcsProductId)?_c('bbcs-service-accounting',{attrs:{"product-id":_vm.bbcsProductId}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }