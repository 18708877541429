<template>
  <div>
    <div v-if="element.title" class="pre-title headline d-flex justify-center">
      <span>{{ element.title }}</span>
    </div>
    <div :class='"full d-flex justify-space-between" + (title ? " font-weight-bold":"") + (subtitle ? " font-italic":"")' >
      <div
        v-if="element.label"
        class="first font-weight-bold flex-grow-0 flex-shrink-0 px-2 text-right"
      >{{ element.label }}</div>
      <div class="flex-grow-1 px-2 next" v-for="(el,i) in element.value" :key="i">{{ el }}</div>
    </div>
  </div>
</template>

<style scoped>
.pre-title {
  margin-bottom: 10px;
}
.full {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
  min-width: fit-content;
}
.full:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
.first {
  width: 200px;
}
.next {
  min-width: 150px;
}
</style>

<script>
export default {
  name: "xdslGeneral",
  props: {
    element: {
      type: Object,
      required: true
    },
    title: {
      type: Boolean
    },
    subtitle: {
      type: Boolean
    },
    
  }
};
</script>