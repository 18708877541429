<template>
<!-- 
  Wenn das Modul überarbeitet wird, müssen die Ankommenden Daten zuerst mir Andi zusammen überarbeitet werden, damit alles schön und übersichtlich wird, besser nicht versuchen den code zu verstehen ;)
 -->

  <div>
    <div v-for="(group, i) in element.value" :key="i">
      <div v-for="(chart, name, i) in group" :key="i" class="d-flex flex-nowrap align-center">
        <div style="width: 100px">{{ $t(name) }}</div><apexchart :options="options" :series="formatData(chart)" height="180px" class="flex-grow-1"/>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import formatDate from "@/utils/mixins/formatDate";

export default {
  name: "xdslCharts",
  components: { apexchart: VueApexCharts },
  mixins: [formatDate],
  props: {
    element: {
      type: Object,
      required: false
    }
  },
  data: function() {
    return {
      someData: null
    };
  },
  computed: {
    options() {
      this.$vuetify.theme.dark; // force re-compute

      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        colors: [
          "#00ffff",
          "#000000",
          "#0000ff",
          "#a52a2a",
          "#00008b",
          "#008b8b",
          "#a9a9a9",
          "#bdb76b",
          "#8b008b",
          "#ff8c00",
          "#9932cc",
          "#8b0000",
          "#e9967a",
          "#9400d3",
          "#ffd700",
          "#008000",
          "#4b0082",
          "#f0e68c",
          "#add8e6",
          "#90ee90",
          "#d3d3d3",
          "#ffb6c1",
          "#00ff00",
          "#ff00ff",
          "#800000",
          "#000080",
          "#808000",
          "#ffa500",
          "#ffc0cb",
          "#800080",
          "#800080",
          "#ff0000",
          "#c0c0c0",
          "#ffff00"
        ],
        chart: {
          background: this.$vuetify.theme.dark ? "#1E1E1E" : "#FFF",
          animations: {
            enabled: false
          },
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
            tools: {
              download: false
            }
          }
        },
        stroke: {
          curve: "smooth",
          width: 2
        },
        tooltip: {
          shared: false
        },
        xaxis: {
          type: "numeric",
          labels: {
            datetimeUTC: false,
            formatter: function(value) {
              return [
                "00:00",
                "00:15",
                "00:30",
                "00:45",
                "01:00",
                "01:15",
                "01:30",
                "01:45",
                "02:00",
                "02:15",
                "02:30",
                "02:45",
                "03:00",
                "03:15",
                "03:30",
                "03:45",
                "04:00",
                "04:15",
                "04:30",
                "04:45",
                "05:00",
                "05:15",
                "05:30",
                "05:45",
                "06:00",
                "06:15",
                "06:30",
                "06:45",
                "07:00",
                "07:15",
                "07:30",
                "07:45",
                "08:00",
                "08:15",
                "08:30",
                "08:45",
                "09:00",
                "09:15",
                "09:30",
                "09:45",
                "10:00",
                "10:15",
                "10:30",
                "10:45",
                "11:00",
                "11:15",
                "11:30",
                "11:45",
                "12:00",
                "12:15",
                "12:30",
                "12:45",
                "13:00",
                "13:15",
                "13:30",
                "13:45",
                "14:00",
                "14:15",
                "14:30",
                "14:45",
                "15:00",
                "15:15",
                "15:30",
                "15:45",
                "16:00",
                "16:15",
                "16:30",
                "16:45",
                "17:00",
                "17:15",
                "17:30",
                "17:45",
                "18:00",
                "18:15",
                "18:30",
                "18:45",
                "19:00",
                "19:15",
                "19:30",
                "19:45",
                "20:00",
                "20:15",
                "20:30",
                "20:45",
                "21:00",
                "21:15",
                "21:30",
                "21:45",
                "22:00",
                "22:15",
                "22:30",
                "22:45",
                "23:00",
                "23:15",
                "23:30",
                "23:45",
                "00:00"
              ][value - 1];
            }
          },
          tickAmount: 8,
          max: 97
        }
      };
    }
  },
  methods: {
    formatData(sets) {
      var new_sets = [];

      for (let inner = 0; inner < sets.length; inner++) {
        var element = sets[inner];

        element["name"] = this.formatDate(element.date);
        element["data"] = element.points;

        new_sets.push(element);
      }

      // console.log(new_sets);

      return new_sets;
    }
  },
  mounted() {
    // console.log(this.element);
  }
};
</script>

<i18n>
{
  "en": {
    "no data": "No data",
    "cv_down":"Coding violations down",
    "cv_up":"Coding violations up",
    "ses_down":"Severe error seconds down",
    "ses_up":"Severe error seconds up"
  },
  "de": {
    "no data": "Keine Daten",
    "cv_down":"Coding violations down",
    "cv_up":"Coding violations up",
    "ses_down":"Severe error seconds down",
    "ses_up":"Severe error seconds up"
  }
}
</i18n>
