<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("status") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="$emit('updateStatus')"
            :disabled="loading"
            :loading="loading"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("refresh line status") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text v-if="loading" />
    <v-card-text v-else-if="Object.entries(status).length === 0">
      {{ $t("could not fetch service status") }}
    </v-card-text>
    <v-card-text v-else>
      <v-simple-table dense>
        <tbody>
          <tr v-if="status.order_number">
            <td>{{ $t("order number") }}</td>
            <td>{{ status.order_number }}</td>
          </tr>
          <tr v-if="status.order_status">
            <td>{{ $t("order status") }}</td>
            <td>{{ status.order_status }}</td>
          </tr>

          <tr>
            <td>{{ $t("interleave") }}</td>
            <td>{{ status.interleave }}</td>
          </tr>
          <tr>
            <td>{{ $t("streaming enabled") }}</td>
            <td>
              <boolean-value :value="Boolean(status.streaming_enabled)" />
            </td>
          </tr>
          <tr></tr>
          <tr v-if="status.sessiontype">
            <td>{{ $t("session type") }}</td>
            <td>{{ status.sessiontype }}</td>
          </tr>
          <tr v-if="status.pool">
            <td>{{ $t("pool") }}</td>
            <td>{{ status.pool }}</td>
          </tr>
          <tr>
            <td>{{ $t("vectorized") }}</td>
            <td>
              <boolean-value :value="Boolean(status.vectorized)" />
            </td>
          </tr>
          <tr>
            <td>{{ $t("broadband type") }}</td>
            <td>{{ status.bbtype }}</td>
          </tr>
          <tr>
            <td>{{ $t("domain type") }}</td>
            <td>{{ status.dntype }}</td>
          </tr>
          <tr>
            <td>{{ $t("UP") }}</td>
            <td>{{ status.up }}</td>
          </tr>
          <tr>
            <td>{{ $t("CPE") }}</td>
            <td>{{ status.cpe }}</td>
          </tr>
          <tr v-if="status.service">
            <td>{{ $t("service profile speed") }}</td>
            <td>{{ status.service.serviceprofile_desc }}</td>
          </tr>
          <tr v-if="status.service">
            <td>{{ $t("service profile speed effective") }}</td>
            <td>
              {{
                (status.service.serviceprofile_effective.down != null
                  ? status.service.serviceprofile_effective.down / 1000
                  : "?") +
                "/" +
                (status.service.serviceprofile_effective.up != null
                  ? status.service.serviceprofile_effective.up / 1000
                  : "?")
              }}
            </td>
          </tr>
          <tr v-if="status.dslam">
            <td>{{ $t("DSLAM type") }}</td>
            <td>{{ status.dslam.type }}</td>
          </tr>
          <tr v-if="status.dslam">
            <td>{{ $t("DSLAM port") }}</td>
            <td>{{ status.dslam.port }}</td>
          </tr>
          <tr v-if="status.dslam">
            <td>{{ $t("DSLAM name") }}</td>
            <td>{{ status.dslam.name }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import BooleanValue from "@/components/basics/BooleanValue";

export default {
  name: "BbcsServiceStatus",
  components: {
    BooleanValue,
  },
  props: {
    status: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<i18n>
{
  "en": {
    "status": "Status",
    "refresh line status": "Refresh line status",
    "could not fetch service status": "Could not fetch service status",
    "order number": "Order number",
    "order status": "Order status",
    "interleave": "Interleave",
    "streaming enabled": "Streaming enabled",
    "pool": "Pool",
    "session type": "Session type",
    "vectorized": "Vectorized",
    "broadband type": "Broadband type",
    "domain type": "Domain type",
    "UP": "UP",
    "CPE": "CPE",
    "realtime enabled": "Realtime enabled",
    "service profile speed": "Service profile speed",
    "service profile speed effective": "Service profile speed effective",
    "DSLAM type": "DSLAM type",
    "DSLAM port": "DSLAM port",
    "DSLAM name": "DSLAM name"
  },
  "de": {
    "status": "Status",
    "refresh line status": "Leitungsstatus aktualisieren",
    "could not fetch service status": "Konnte den Dienststatus nicht abrufen",
    "order number": "Bestellnummer",
    "order status": "Bestellstatus",
    "interleave": "Interleave",
    "streaming enabled": "Streaming aktiviert",
    "pool": "Pool",
    "session type": "Sitzungstyp",
    "vectorized": "Vectorized",
    "broadband type": "Broadband Typ",
    "domain type": "Domain Typ",
    "UP": "UP",
    "CPE": "CPE",
    "realtime enabled": "Realtime aktiviert",
    "service profile speed": "Service Profile Geschwindigkeit",
    "service profile speed effective": "Service Profile Geschwindigkeit effektiv",
    "DSLAM type": "DSLAM Typ",
    "DSLAM port": "DSLAM Port",
    "DSLAM name": "DSLAM Name"
  }
}
</i18n>
