<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("BBCS up/downgrade") }}
      </v-toolbar-title>
    </v-toolbar>

    <v-card-text class="mt-4">
      <v-stepper v-model="step" alt-labels>
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            {{ $t("profile") }}
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">
            {{ $t("options") }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card elevation="0" color="transparent">
              <validation-observer
                v-slot="{ invalid }"
                ref="opsStep1"
                :disabled="step !== 1"
              >
                <v-card-text class="mb-12" v-if="step === 1">
                  <profiles-bbcs
                    :checking="loading"
                    :current-product-id="product.id"
                    :current-variant-id="
                      bbcsService.gfo_variant != null
                        ? bbcsService.gfo_variant.id
                        : null
                    "
                    observer-name="opsStep1"
                    :fire="fireBbcsQuery"
                    @fired="fireBbcsQuery = false"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="$emit('upgradeCanceled')" text>
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="step = step + 1"
                    :disabled="
                      invalid || loading || fireBbcsQuery || !bbcsResultStateOk
                    "
                    text
                  >
                    {{ $t("continue") }}
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card elevation="0" color="transparent">
              <validation-observer
                v-slot="{ invalid }"
                ref="opsStep2"
                :disabled="step !== 2"
              >
                <v-card-text class="mb-12" v-if="step === 2">
                  <order-summary class="mb-4" />
                  <create-order :service="bbcsService" upgrade />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="$emit('upgradeCanceled')" text>
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn @click="step = step - 1" :disabled="ordering" text>
                    {{ $t("back") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="order"
                    :disabled="invalid || ordering"
                    :loading="ordering"
                    text
                  >
                    {{ $t("order") }}
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import ProfilesBbcs from "@/components/orders/internet/ProfilesBbcs";
import CreateOrder from "@/components/orders/internet/CreateOrder";
import OrderSummary from "@/components/orders/internet/OrderSummary";
import showErrors from "@/utils/mixins/showErrors";

const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("inetOrder");

export default {
  name: "BbcsUpdate",
  mixins: [showErrors],
  components: {
    ProfilesBbcs,
    CreateOrder,
    OrderSummary,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    bbcsProfile: {
      type: Object,
      required: true,
    },
    bbcsService: {
      type: Object,
      required: true,
    },
    bbcsServiceStatus: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    ordering: false,
    fireBbcsQuery: false,
    step: 1,
  }),
  watch: {
    "$store.state.session": {
      immediate: true,
      handler(value) {
        this.readBbcsProperties();
        this.prepare();
      },
    },
    bbcsService() {
      // update GFO options
      this.setOptions(this.bbcsService.gfo_options);
    },
  },
  computed: {
    ...mapState([
      "bbcsRadiusPassword",
      "bbcsRadiusSuffixIdx",
      "productDescr",
      "sessionType",
      "pool",
      "streaming",
      "orderEmail",
      "orderNote",
      "options",
      "wishDate",
      "slaEmail",
      "slaMobile",
    ]),
    ...mapGetters([
      "bbcsRadiusSuffixes",
      "bbcsUsernameWithSuffix",
      "bbcsResultStateOk",
      "service",
      "profile",
      "timeSlot",
    ]),
    bbType: {
      ...mapState({ get: "bbType" }),
      ...mapMutations({ set: "setBbType" }),
    },
    currentSlaEmail() {
      if (this.product != null) {
        var slas = this.product.variants.filter(function (v) {
          return v.tags.includes("sla");
        });
        if (slas.length > 0) {
          var attrs = slas[0].attributes.filter(function (a) {
            return a.attr_code == "SLA-MAIL";
          });
          if (attrs.length > 0) return attrs[0].attr_value;
        }
      }
      return "";
    },
    currentSlaMobile() {
      if (this.product != null) {
        var slas = this.product.variants.filter(function (v) {
          return v.tags.includes("sla");
        });
        if (slas.length > 0) {
          var attrs = slas[0].attributes.filter(function (a) {
            return a.attr_code == "SLA-MOBILE";
          });
          if (attrs.length > 0) return attrs[0].attr_value;
        }
      }
      return "";
    },
  },
  methods: {
    ...mapMutations([
      "setCheckIdx",
      "setCheckResults",
      "setOto",
      "setPlug",
      "setNumber",
      "setStreaming",
      "setPool",
      "setSessionType",
      "setNewLoop",
      "setLineState",
      "setOptions",
      "setBbcsRadiusSuffixIdx",
      "setBbcsRadiusUsername",
      "setBbcsRadiusPassword",
      "setProductDescr",
      "setOrderEmail",
      "setOrderNote",
      "setSlaEmail",
      "setSlaMobile",
    ]),
    ...mapActions([
      "resetCheckResults",
      "resetOrderContact",
      "setBbcsProperties",
    ]),
    prepare() {
      /* prepare ProfilesBbcs component
       * TODO: review later
       */
      var that = this;
      this.step = 1;

      var bx = this.bbcsProfile.tags.includes("bbcs-f");
      var xgs = this.bbcsServiceStatus.bbtype == "XGS";
      var bbtype = this.bbcsServiceStatus.bbtype;
      this.setCheckIdx(bx ? 2 : 1); // OTO or NUMBER
      this.resetOrderContact();
      this.resetCheckResults([
        {
          id: 0,
          provider: bx ? "bbcs_fiber" : xgs ? "bbcs_xgspon" : "bbcs",
          services: [
            {
              id: 0,
              name: bbtype,
              maxDownStream: 0,
              maxUpStream: 0,
              address: {},
              otos: [],
              profiles: [],
              timeSlots: [],
            },
          ],
        },
      ]);
      this.setOto(this.bbcsService.oto || "");
      this.setPlug(this.bbcsService.plug || "");
      this.setNumber(this.bbcsService.dn || "");
      this.bbType = bbtype;
      this.setStreaming(this.bbcsServiceStatus.streaming_enabled || false);
      this.setPool(this.bbcsServiceStatus.pool || "pool1");
      this.setSessionType(this.bbcsServiceStatus.sessiontype || "DHCP");
      this.setNewLoop(false);
      this.setLineState("all");
      this.setProductDescr(this.product.description || "");
      this.setOrderEmail(this.$store.getters.deliveryEmail || "");
      this.setOrderNote("");
      this.setSlaEmail(this.currentSlaEmail);
      this.setSlaMobile(this.currentSlaMobile);

      // prepare RADIUS setting
      // (bbcsRadiusSuffixIdx will be set in store action setBbcsProperties
      // after loading properties
      var radiusUsername =
        this.bbcsService.radius_username_parsed != null
          ? this.bbcsService.radius_username_parsed.username
          : this.bbcsService.radius_username != null
          ? this.bbcsService.radius_username.split("@", 2)[0]
          : this.bbcsService.dn || "";
      this.setBbcsRadiusUsername(radiusUsername);
      this.setBbcsRadiusPassword(this.bbcsService.radius_password || "");

      // fire BBCS quali
      this.fireBbcsQuery = true;
    },
    order() {
      var that = this;
      this.ordering = true;
      var params = {
        options: this.options.map(function (opt) {
          return {
            id: opt.id,
            quantity: opt.disabled ? 0 : opt.quantity,
          };
        }),
        email: this.orderEmail,
        sla_email: this.slaEmail,
        sla_mobile: this.slaMobile,
        product_description: this.productDescr || null,
        wishdate: this.wishDate,
        radius_username:
          this.sessionType === "PPP"
            ? this.bbcsUsernameWithSuffix || null
            : null,
        radius_password:
          this.sessionType === "PPP" ? this.bbcsRadiusPassword || null : null,
        bbtype: this.bbType,
        sessiontype: this.sessionType,
        pool: this.pool,
        streaming: this.streaming,
        /*
        quali_id: this.service.quali_id,
        quali_profile_id: this.profile.quali_profile_id,
        quali_timeslot_id:
          this.timeSlot != null ? this.timeSlot.quali_timeslot_id : null,
        */
        profile_number: this.profile.profile_nr,
        note: this.orderNote || null,
      };

      this.$http
        .put("services/bbcs/" + this.product.id, params)
        .then((response) => {
          that.$snotify.success(
            that.$i18n.t(
              "Your {worker} {subject} has been successfully commited.",
              {
                worker: response.data.worker_name,
                subject: response.data.subject,
              }
            )
          );
          that.$router.push({
            name: "tasks",
          });
          that.step = 1;
        })
        .catch((error) => {
          if (error.invalid()) {
            that.$refs.opsStep2.setErrors(error.data);
            that.showErrors(that.$refs.opsStep2.getUnresolvedErrors(error));
          } else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.ordering = false;
        });
    },
    readBbcsProperties: function () {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/bbcs/properties")
        .then((response) => {
          var suffix =
            that.bbcsService.radius_username_parsed != null
              ? that.bbcsService.radius_username_parsed.suffix
              : null;
          that.setBbcsProperties({ value: response.data, suffix: suffix });
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "BBCS up/downgrade": "BBCS up/downgrade",
    "profile": "Profile",
    "options": "Options",
    "order": "Order",
    "wishdate": "Wishdate",
    "check": "Check",
    "back": "Back",
    "cancel": "Cancel",
    "continue": "Continue",
    "Your {worker} {subject} has been successfully commited.": "Your {worker} {subject} has been successfully commited."
  }, 
  "de": {
    "BBCS up/downgrade": "BBCS Up/Downgrade",
    "profile": "Profil",
    "options": "Optionen",
    "order": "Bestellung",
    "wishdate": "Wunschdatum",
    "check": "prüfen",
    "back": "zurück",
    "cancel": "Abbrechen",
    "continue": "weiter",
    "Your {worker} {subject} has been successfully commited.": "Ihre {worker} {subject} wurde erfolgreich übermittelt."
  }
}
</i18n>
