<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("connection history") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="getEntries"
            :disabled="loading"
            :loading="loading"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("refresh history") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="entries"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        sort-by="start_time"
        sort-desc
        :hide-default-footer="entries.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
      >
        <template v-slot:top v-if="lastEntry != null">
          <v-card>
            <v-simple-table dense class="mb-4">
              <tbody>
                <tr>
                  <td>{{ $t("connection status") }}</td>
                  <td>
                    <v-chip
                      :color="
                        lastEntry.end_time == null ? 'success' : 'warning'
                      "
                      class="mt-2 mb-2"
                    >
                      {{
                        lastEntry.end_time == null
                          ? $t("online")
                          : $t("offline")
                      }}
                    </v-chip>
                  </td>
                </tr>
                <tr
                  v-if="
                    lastEntry.end_time == null && lastEntry.ipaddress != null
                  "
                >
                  <td>{{ $t("current IP address") }}</td>
                  <td>
                    <v-chip class="mt-2 mb-2">{{ lastEntry.ipaddress }}</v-chip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </template>

        <template v-slot:item.start_time="{ item }">
          <div class="monospace">{{ formatDateTime(item.start_time) }}</div>
        </template>

        <template v-slot:item.end_time="{ item }">
          <div class="monospace">
            {{ item.end_time ? formatDateTime(item.end_time) : "" }}
          </div>
        </template>

        <template v-slot:item.input_octets="{ item }">
          <div class="monospace">
            {{ prettyBytes(item.input_octets || 0) }}
          </div>
        </template>

        <template v-slot:item.output_octets="{ item }">
          <div class="monospace">
            {{ prettyBytes(item.output_octets || 0) }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import prettyBytes from "@/utils/mixins/prettyBytes";
import isMobile from "@/utils/mixins/isMobile";

const ip = require("@/utils/ip");

export default {
  name: "BbcsServiceAccounting",
  mixins: [formatDateTime, prettyBytes, isMobile],
  props: {
    productId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    entries: [],
  }),
  watch: {
    productId: "getEntries",
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("ID"),
          value: "id",
        },
        {
          text: this.$i18n.t("start time"),
          value: "start_time",
        },
        {
          text: this.$i18n.t("end time"),
          value: "end_time",
        },
        {
          text: this.$i18n.t("input"),
          value: "input_octets",
        },
        {
          text: this.$i18n.t("output"),
          value: "output_octets",
        },
        {
          text: this.$i18n.t("terminate cause"),
          value: "terminate_cause",
        },
        {
          text: this.$i18n.t("IP address"),
          value: "ipaddress",
          sort: (a, b) => {
            return ip.compare(a, b);
          },
        },
      ];
    },
    lastEntry() {
      this.entries;
      if (this.entries.length > 0) {
        var entries = [...this.entries];
        entries.sort(function(a, b) {
          var dateB = new Date(a.start_time);
          var dateA = new Date(b.start_time);
          return dateA - dateB;
        });
        return entries[0];
      }
    },
  },
  methods: {
    getEntries: function() {
      var that = this;
      if (this.productId != null) {
        this.loading = true;
        this.$http
          .get("services/bbcs/" + this.productId + "/accounting")
          .then((response) => {
            that.entries = response.data;
          })
          .catch((err) => {
            if (err.status != 400) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function() {
            that.loading = false;
          });
      }
    },
  },
  mounted: function() {
    this.getEntries();
  },
};
</script>

<i18n>
{
  "en": {
    "connection history": "Connection history",
    "refresh history": "Refresh history",
    "connection status": "Connection status",
    "current IP address": "Current IP address",
    "online": "online",
    "offline": "offline",
    "ID": "ID",
    "start time": "Start time",
    "end time": "End time",
    "input": "Received",
    "output": "Send",
    "terminate cause": "Terminate cause",
    "IP address": "IP address"
  },
  "de": {
    "connection history": "Verbindungsrückblick",
    "refresh history": "Rückblick aktualisieren",
    "connection status": "Verbindungs Status",
    "current IP address": "aktuelle IP Adresse",
    "online": "online",
    "offline": "offline",
    "ID": "ID",
    "start time": "Startzeit",
    "end time": "Endzeit",
    "input": "Gesendet",
    "output": "Empfangen",
    "terminate cause": "Abbruch Code",
    "IP address": "IP Adresse"
  }
}
</i18n>
