<template>
  <div>
    <!-- <code>{{ element }}</code> -->
    <div v-if="element.title" class="pre-title headline d-flex justify-center">
      <span>{{ element.title }}</span>
    </div>
    <div class="full d-flex justify-space-between">
      <div
        v-if="element.label"
        class="first font-weight-bold flex-grow-0 flex-shrink-0 px-2 text-right"
      >{{ element.label }}</div>
      <div class="flex-grow-1 px-2 next" v-for="(el,i) in element.value" :key="i">
        <div class="ample-outer">
          <div v-if="el === 'red'" class="ample-inner" style="background-color: #eb1d0e"></div>
          <div v-else class="ample-inner"></div>

          <div v-if="el === 'yellow'" class="ample-inner" style="background-color: #fcba03"></div>
          <div v-else class="ample-inner"></div>

          <div v-if="el === 'green'" class="ample-inner" style="background-color: #32a852"></div>
          <div v-else class="ample-inner"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pre-title {
  margin-bottom: 10px;
}
.full {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
  min-width: fit-content;
}
/* .full:hover {
  background-color: rgba(0, 0, 0, 0.12);
} */
.first {
  width: 200px;
}
.next {
  min-width: 150px;
}
.ample-outer {
  width: 40px;
  height: 120px;
  background-color: grey;
  box-shadow: 2px 3px 1px 0px #606060;
  border-radius: 3px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-evenly;
}
.ample-inner {
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 3px 3px 0px rgba(0, 0, 0, 0.5);
  border-radius: 100%;
}
</style>

<script>
export default {
  name: "xdslTrafficLight",
  props: {
    element: {
      type: Object,
      required: true
    }
  }
};
</script>