<template>
  <v-card :loading="loading">
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ service.dn }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              $router.push({
                name: 'product-details',
                params: { productId: bbcsProductId },
              })
            "
            :disabled="loading || !hasProductRole"
            icon
          >
            <v-icon>mdi-text-box</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("open product") }}</span>
      </v-tooltip>

      <v-tooltip v-if="$store.state.session.partnerType == 'WHO'" top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="$router.push({ name: 'internet-order' })"
            :disabled="$store.getters.isReadOnly || !hasOrderRole"
            icon
          >
            <v-icon>mdi-plus-network-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("add new internet service") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="xDslInfoDialog = true"
            :disabled="loading || xDslInfoDialog"
            icon
          >
            <v-icon>mdi-help-network-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("xDSL status information") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="ticketDialog = true"
            :disabled="
              loading ||
              ticketDialog ||
              profile === null ||
              !canTicket ||
              $store.getters.isReadOnly
            "
            icon
          >
            <v-icon>mdi-progress-wrench</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("create BBCS ticket") }}</span>
      </v-tooltip>

      <wiki slug="services-bbcs-details" />
    </v-toolbar>

    <v-dialog v-model="ticketDialog" width="80%" scrollable persistent>
      <bbcs-ticket
        v-if="profile"
        :profile="profile"
        :product-id="bbcsProductId"
        @ticketCanceled="ticketDialog = false"
        @ticketCreated="
          ticketDialog = false;
          getWorkers();
        "
      />
    </v-dialog>

    <v-dialog v-model="xDslInfoDialog" width="80%" scrollable persistent>
      <bbcs-xdsl-info
        v-if="bbcsProductId && xDslInfoDialog"
        :product-id="bbcsProductId"
        @close="xDslInfoDialog = false"
      />
    </v-dialog>

    <v-dialog v-model="upgradeDialog" width="80%" scrollable persistent>
      <bbcs-upgrade
        v-if="upgradeDialog"
        :product="product"
        :bbcs-profile="profile"
        :bbcs-service="service"
        :bbcs-service-status="serviceStatus"
        @upgradeCanceled="upgradeDialog = false"
        @upgradeOrdered="upgradeDialog = false"
      />
    </v-dialog>

    <v-dialog v-model="ipSetupDialog" width="80%" scrollable>
      <ip-setup
        v-if="ipSetupDialog"
        :product="product"
        @close="ipSetupDialog = false"
      />
    </v-dialog>

    <v-card-text>
      <v-card outlined class="mb-4" v-if="profile">
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title> {{ profile.product_type_name }}</v-toolbar-title>
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="upgradeDialog = true"
                :disabled="
                  loading ||
                  upgradeDialog ||
                  profile === null ||
                  !canOrder ||
                  $store.getters.isReadOnly ||
                  !hasOrderRole
                "
                icon
              >
                <v-icon>mdi-tune</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("up/downgrade line") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="ipSetupDialog = true"
                :disabled="loading || !isPpp"
                icon
              >
                <v-icon>mdi-ip</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("open IP setup") }}</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <product-attributes :attributes="profile.attributes" />
        </v-card-text>
      </v-card>

      <device-info
        v-if="bbcsProductId"
        :product-id="bbcsProductId"
        class="mb-4"
      />

      <bbcs-service-status
        v-if="bbcsProductId"
        :status="serviceStatus"
        :loading="loadingServiceStatus"
        @updateStatus="getServiceStatus"
        class="mb-4"
      />

      <bbcs-service-accounting
        v-if="bbcsProductId"
        :product-id="bbcsProductId"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ProductAttributes from "@/components/products/ProductAttributes";
import BbcsServiceStatus from "@/components/services/bbcs/BbcsServiceStatus";
import BbcsServiceAccounting from "@/components/services/bbcs/BbcsServiceAccounting";
import BbcsTicket from "@/components/services/bbcs/BbcsTicket";
import BbcsXdslInfo from "@/components/services/bbcs/BbcsXdslInfo";
import BbcsUpgrade from "@/components/services/bbcs/BbcsUpgrade";
import DeviceInfo from "@/components/services/internet/DeviceInfo";
import IpSetup from "@/components/services/internet/IpSetup";
import commonAddOns from "@/utils/mixins/commonAddOns";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "BbcsDetails",
  components: {
    BbcsServiceStatus,
    BbcsServiceAccounting,
    BbcsTicket,
    BbcsXdslInfo,
    BbcsUpgrade,
    ProductAttributes,
    DeviceInfo,
    IpSetup,
    Wiki,
  },
  mixins: [commonAddOns],
  props: {
    bbcsProductId: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      loadingService: false,
      loadingProduct: false,
      loadingServiceStatus: false,
      loadingWorkers: false,
      ticketDialog: false,
      upgradeDialog: false,
      xDslInfoDialog: false,
      ipSetupDialog: false,
      service: {},
      product: {},
      serviceStatus: {},
      profile: null,
      workers: [],
    };
  },
  computed: {
    loading: function () {
      return (
        this.loadingService ||
        this.loadingProduct ||
        this.loadingServiceStatus ||
        this.loadingWorkers
      );
    },
    canOrder() {
      return (
        this.workers.filter(function (w) {
          return w.worker_code === "workerticketbbcsorder";
        }).length === 0 && !this.loadingWorkers
      );
    },
    canTicket() {
      return (
        this.workers.filter(function (w) {
          return (
            w.worker_code === "workerticketbbcsticket" ||
            w.worker_code === "workerticketbbcsiway"
          );
        }).length === 0 && !this.loadingWorkers
      );
    },
    isPpp() {
      return this.serviceStatus.sessiontype != null
        ? this.serviceStatus.sessiontype == "PPP"
        : this.service.radius_username != null
        ? !this.service.radius_username.endsWith("@dhcp")
        : false;
    },
  },
  watch: {
    bbcsProductId: function () {
      this.service = {};
      this.product = {};
      this.serviceStatus = {};
      this.profile = null;
      this.getService();
      this.getProduct();
      this.getServiceStatus();
      this.getWorkers();
    },
  },
  methods: {
    getService: function () {
      var that = this;
      if (this.bbcsProductId) {
        this.loadingService = true;
        this.$http
          .get("services/bbcs/" + this.bbcsProductId)
          .then((response) => {
            that.service = response.data;
          })
          .catch((err) => {
            if (err.invalid()) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function () {
            that.loadingService = false;
          });
      }
    },
    getProduct: function () {
      var that = this;
      if (this.bbcsProductId) {
        this.loadingProduct = true;
        this.$http
          .get("products/" + this.bbcsProductId, {
            disableDefaultErrorHandler: true,
          })
          .then((response) => {
            that.product = response.data;
            that.product.variants.forEach((item) => {
              if (item.component_code === "PF") that.profile = item;
            });
          })
          .catch((err) => {
            if (err.status == 403) {
              console.log(
                "user or person seems not allowed to see this product details"
              );
            } else
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
          })
          .finally(function () {
            that.loadingProduct = false;
          });
      }
    },
    getServiceStatus: function () {
      var that = this;
      if (this.bbcsProductId != null) {
        this.loadingServiceStatus = true;
        this.$http
          .get("services/bbcs/" + this.bbcsProductId + "/status")
          .then((response) => {
            that.serviceStatus = response.data;
          })
          .catch((err) => {
            if (err.status != 400) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function () {
            that.loadingServiceStatus = false;
          });
      }
    },
    getWorkers: function () {
      var that = this;
      if (this.bbcsProductId) {
        this.loadingWorkers = true;
        this.$http
          .get("workers", {
            disableDefaultErrorHandler: true,
            params: {
              aplix_object_id: this.bbcsProductId,
              done: false,
            },
          })
          .then((response) => {
            that.workers = response.data;
          })
          .catch((err) => {
            if (err.status == 403) {
              console.log(
                "user or person seems not allowed to see workers for this product"
              );
            } else
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
          })
          .finally(function () {
            that.loadingWorkers = false;
          });
      }
    },
  },
  mounted: function () {
    this.getService();
    this.getProduct();
    this.getServiceStatus();
    this.getWorkers();
  },
};
</script>

<i18n>
{
  "en": {
    "xDSL status information": "xDSL status information",
    "create BBCS ticket": "Create BBCS ticket",
    "up/downgrade line": "Up/downgrade line",
    "product": "Product",
    "open product": "Open product",
    "open IP setup": "Open IP setup",
    "add new internet service": "Add new internet service"
  },
  "de": {
    "xDSL status information": "xDSL Status Information",
    "create BBCS ticket": "BBCS Ticket anlegen",
    "up/downgrade line": "Up/Downgrade Anschluss",
    "product": "Produkt",
    "open product": "Produkt öffnen",
    "open IP setup": "IP Einstellungen öffnen",
    "add new internet service": "Neuen Internet Dienst hinzufügen"
  }
}
</i18n>
