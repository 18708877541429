<template>
  <validation-observer ref="obs" v-slot="{ errors, invalid, validate }" slim>
    <v-card>
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>{{ $t("create BBCS ticket") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="$emit('ticketCanceled')" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("close") }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text class="pt-2">
        <v-card outlined>
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>{{ $t("end user data") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  vid="user_name"
                  :name="$t('name')"
                  rules="required|max:250"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="user.user_name"
                    name="user_name"
                    :label="$t('name')"
                    :hint="$t('end users contact name')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :maxlength="250"
                    :class="classes"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <address-postal
              v-model="user"
              zip-name="user_zip"
              city-name="user_city"
              street-name="user_street"
              street-number-name="user_house_nr"
              additional-hide
              country-hide
            />
            <v-row>
              <v-col cols="12">
                <validation-provider
                  vid="user_building"
                  :name="$t('building')"
                  rules="max:250"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="user.building"
                    name="user_building"
                    :label="$t('building')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :maxlength="250"
                    :class="classes"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <validation-provider
                  vid="user_phone_nr"
                  :name="$t('phone')"
                  rules="phone|max:250"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="user.user_phone_nr"
                    name="user_phone_nr"
                    :label="$t('phone')"
                    :hint="$t('end users contact phone number')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :maxlength="250"
                    @change="
                      user.user_phone_nr = formatPhoneNumber(user.user_phone_nr)
                    "
                    :class="classes"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  vid="user_login"
                  :name="$t('user login')"
                  rules="max:250"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="user.user_login"
                    name="user_login"
                    :label="$t('user login')"
                    :hint="$t('end users session login name')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :maxlength="250"
                    :class="classes"
                    disabled
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card outlined class="mt-2">
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>{{ $t("contact data") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <validation-provider
                  vid="contact_first_name"
                  :name="$t('first name')"
                  rules="max:250"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="contact.contact_first_name"
                    name="contact_first_name"
                    :label="$t('first name')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :maxlength="250"
                    :class="classes"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  vid="contact_last_name"
                  :name="$t('last name')"
                  rules="required|max:250"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="contact.contact_last_name"
                    name="contact_last_name"
                    :label="$t('last name')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :maxlength="250"
                    :class="classes"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <validation-provider
                  vid="contact_lang"
                  :name="$t('contact language')"
                  rules="max:250"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <language-select
                    v-model="contact.contact_lang"
                    name="contact_lang"
                    :label="$t('contact language')"
                    :success="dirty && valid"
                    :error-messages="errors"
                    :class="classes"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  vid="contact_phone_nr"
                  :name="$t('phone number')"
                  rules="required|phone|max:250"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="contact.contact_phone_nr"
                    name="contact_phone_nr"
                    :label="$t('phone number')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :maxlength="250"
                    :class="classes"
                    @change="
                      contact.contact_phone_nr = formatPhoneNumber(
                        contact.contact_phone_nr
                      )
                    "
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="contact.notify_type === 'none' 12 : 6">
                <validation-provider
                  vid="contact_notify_type"
                  :name="$t('notify type')"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-select
                    v-model="contact.contact_notify_type"
                    name="contact_notify_type"
                    :items="contactNotifyTypes"
                    :label="$t('notify by')"
                    :success="dirty && valid"
                    :error-messages="errors"
                    :class="classes"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip color="primary">{{ $t(item) }}</v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-chip color="primary">{{ $t(item) }}</v-chip>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
              <v-col cols="6" v-if="contact.contact_notify_type !== 'none'">
                <validation-provider
                  vid="contact_notify_addr"
                  :name="
                    contact.contact_notify_type === 'email'
                      ? $t('contact email address')
                      : $t('contact SMS number')
                  "
                  :rules="
                    'required|max:250|' +
                    (contact.contact_notify_type === 'email'
                      ? 'email'
                      : 'phone')
                  "
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="contact.contact_notify_addr"
                    name="contact_notify_addr"
                    :label="
                      contact.contact_notify_type === 'email'
                        ? $t('contact email address')
                        : $t('contact SMS number')
                    "
                    :error-messages="errors"
                    :success="dirty && valid"
                    :maxlength="250"
                    :class="classes"
                    @change="
                      contact.contact_notify_addr =
                        contact.contact_notify_type === 'sms'
                          ? formatPhoneNumber(contact.contact_notify_addr)
                          : contact.contact_notify_addr
                    "
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card outlined class="mt-2">
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>{{ $t("issue details") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <span class="v-input">{{ $t("error category") }}</span>
                <validation-provider
                  vid="serv_err_cat"
                  :name="$t('service error category')"
                  rules="required"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-chip-group
                    v-model="servErrCatIdx"
                    name="serv_err_cat"
                    :label="$t('service error category')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :mobile-breakpoint="mbreakpoint"
                    :class="classes"
                    mandatory
                    column
                    color="primary"
                  >
                    <v-chip
                      v-for="(cat, i) in availableServErrCats"
                      :key="i"
                      outlined
                      label
                      filter
                    >
                      {{ $t(cat) }}
                    </v-chip>
                  </v-chip-group>
                </validation-provider>
                <v-alert type="info" class="mt-2" prominent outlined>
                  {{ $t(availableServErrCats[servErrCatIdx] + "__descr") }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  vid="serv_equipment"
                  :name="$t('equipment')"
                  rules="required|max:250"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="servEquipment"
                    name="serv_equipment"
                    :label="$t('equipment')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :maxlength="250"
                    :class="classes"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <validation-provider
                  vid="problem_date"
                  :name="$t('problem date')"
                  rules="required"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <date-picker
                    name="problem_date"
                    v-model="problemDate"
                    :label="$t('problem date')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    :allowedDates="dateAllowed"
                    today
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  vid="problem_time"
                  :name="$t('problem time')"
                  rules="required"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <time-picker
                    name="problem_time"
                    v-model="problemTime"
                    :label="$t('problem time')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    :allowedHours="hourAllowed"
                    :allowedMinutes="minuteAllowed"
                    now
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  vid="problem_descr"
                  :name="$t('problem description')"
                  rules="required|max:1024"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-textarea
                    name="problem_descr"
                    v-model="problemDescr"
                    :label="$t('problem description')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    rows="3"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-switch
                  v-model="viaBbcs"
                  :label="$t('create ticket directly with Swisscom')"
                  @change="accepted = false"
                />
              </v-col>
              <v-col cols="9" v-if="viaBbcs">
                <v-alert type="error" prominent outlined>
                  <v-row align="center">
                    <v-col class="grow">
                      {{ $t("acceptMsg") }}
                    </v-col>
                    <v-col class="shrink">
                      <v-checkbox v-model="accepted" color="error" />
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('ticketCanceled')" text :disabled="loading">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          @click="createTicket"
          class="primary"
          :loading="loading"
          :disabled="loading || invalid || (viaBbcs && !accepted)"
          text
        >
          {{ $t("create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import parseAplixInstallAddress from "@/utils/convert/ParseAplixInstallAddress";
import formatPhoneNumber from "@/utils/mixins/formatPhoneNumber";
import AddressNames from "@/components/basics/AddressNames";
import AddressContact from "@/components/basics/AddressContacts";
import AddressPostal from "@/components/basics/AddressPostal";
import LanguageSelect from "@/components/basics/LanguageSelect";
import DatePicker from "@/components/basics/DatePicker";
import TimePicker from "@/components/basics/TimePicker";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "BbcsTicket",
  components: {
    AddressNames,
    AddressContact,
    AddressPostal,
    LanguageSelect,
    DatePicker,
    TimePicker,
  },
  mixins: [formatPhoneNumber, showErrors, isMobile],
  props: {
    profile: {
      type: Object,
      required: true,
    },
    productId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    email: "",
    viaBbcs: false,
    accepted: false,
    ttRef: "",
    user: {
      user_name: "",
      user_zip: "",
      user_city: "",
      user_street: "",
      user_house_nr: "",
      user_building: "",
      user_login: "",
      user_phone_nr: "",
      country: "CH", // force swiss address form
    },
    contact: {
      contact_first_name: "",
      contact_last_name: "",
      contact_phone_nr: "",
      contact_notify_type: "none",
      contact_notify_addr: "",
      contact_lang: "de",
    },
    contactNotifyTypes: ["none", "email", "sms"],
    servEquipment: "",
    servErrCatIdx: 0,
    servErrCats: [
      "xdsl_unknown",
      "xdsl_no_signal",
      "xdsl_no_connection",
      "xdsl_interuption",
      "xdsl_performance",
      // "xdsl_undefined_line", TODO: temp. fix for MCF-2329
      "fiber_no_signal",
      "fiber_no_connection",
      "fiber_interuption",
      "fiber_performance",
      // "fiber_coord",
      // "fiber_bbcs_f_2nd_level_analyses",
    ],
    problemDate: "",
    problemTime: "",
    problemDescr: "",
  }),
  computed: {
    availableServErrCats() {
      var that = this;
      return this.servErrCats.filter(function (cat) {
        if (that.profile.tags.includes("bbcs-f"))
          return cat.search("fiber_") === 0;
        else if (that.profile.tags.includes("bbcs"))
          return cat.search("xdsl_") === 0;
        else return cat.search("fiber_") === 0;
      });
    },
    installAddress() {
      var installlAddrAttr = this.profile.attributes.filter(function (attr) {
        return attr.attr_code === "INSTADR";
      });
      if (installlAddrAttr.length > 0 && installlAddrAttr[0].attr_value) {
        return parseAplixInstallAddress(installlAddrAttr[0].attr_value);
      }
      return {
        zip: "",
        city: "",
        street: "",
        street_number: "",
      };
    },
    username() {
      var usernameAttr = this.profile.attributes.filter(function (attr) {
        return attr.attr_code === "UN";
      });
      return usernameAttr.length > 0 ? usernameAttr[0].attr_value : "";
    },
  },
  watch: {
    profile: {
      immediate: true,
      handler() {
        var installAddress = this.installAddress;
        this.user = {
          ...this.user,
          user_zip: installAddress.zip,
          user_city: installAddress.city,
          user_street: installAddress.street,
          user_house_nr: installAddress.street_number,
          user_login: this.username,
        };
        this.contact = {
          ...this.contact,
          contact_phone_nr:
            this.contact_phone_nr ||
            this.$store.getters.address.phone ||
            this.$store.getters.address.mobile,
          contact_lang: (this.$store.getters.language || "de").toLowerCase(),
        };
      },
    },
  },
  methods: {
    createTicket() {
      var that = this;
      var params = {
        ...this.user,
        ...this.contact,
        tt_ref: this.productId, // TODO: remove to backend
        via_iway: !this.viaBbcs,
        serv_equipment: this.servEquipment,
        serv_err_cat: this.availableServErrCats[this.servErrCatIdx],
        problem_date: this.$moment(this.problemDate + " " + this.problemTime),
        problem_descr: this.problemDescr,
      };
      this.loading = true;
      this.$http
        .post("services/bbcs/" + this.productId + "/ticket", params)
        .then((response) => {
          that.$snotify.success(
            that.$i18n.t(
              "Your {worker_name} ticket '{subject}' has been successfully created.",
              {
                subject: response.data.subject,
                worker_name: response.data.worker_name,
              }
            )
          );
          that.$emit("ticketCreated");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
    dateAllowed(date) {
      const today = this.$moment(new Date()).format("YYYY-MM-DD");
      return (
        this.$moment(today).isSame(date, "day") ||
        this.$moment(today).isAfter(date)
      );
    },
    hourAllowed(hour) {
      // allow all hours if problemDate is before today
      const today = this.$moment(new Date()).format("YYYY-MM-DD");
      if (this.problemDate && this.$moment(today).isAfter(this.problemDate))
        return true;
      return hour <= this.$moment().hour();
    },
    minuteAllowed(minute) {
      // allow all minutes if problemDate is before today
      const today = this.$moment(new Date()).format("YYYY-MM-DD");
      if (this.problemDate && this.$moment(today).isAfter(this.problemDate))
        return true;
      return minute <= this.$moment().minute();
    },
  },
};
</script>

<style>
.ticket-worklog caption,
.ticket-history caption {
  font-weight: bolder;
  background-color: lightgray;
  border: 1px solid #aaa;
  padding: 10px;
  margin-bottom: 5px;
}
.ticket-worklog table,
.ticket-history table {
  border: 1px solid #aaa;
  width: 100%;
  margin-bottom: 25px;
  padding: 10px;
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: gray;
}
.ticket-worklog table > tr,
.ticket-history table > tr {
  vertical-align: bottom;
}
.ticket-worklog table tr td,
.ticket-history table tr td {
  border: 1px solid #aaa;
  padding: 2px 5px 2px 5px;
  text-align: left;
  vertical-align: middle;
}
</style>

<i18n>
{
  "en": {
    "create BBCS ticket": "Create BBCS ticket",
    "create": "Create",
    "cancel": "Cancel",
    "close": "Close",
    "end user data": "end user data",
    "phone": "phone",
    "building": "Building",
    "user login": "User login",
    "contact data": "Contact data",
    "contact language": "Contact language",
    "phone number": "Phone number",
    "notify type": "Notify type",
    "issue details": "Issue details",
    "error category": "Error category",
    "service error category": "Service error category",
    "equipment": "Equipment",
    "problem date": "Problem date",
    "problem time": "Problem time",
    "problem description": "Problem description",
    "notify by": "Notify by",
    "end users contact phone number": "end users contact phone number",
    "end users contact name": "end users contact name",
    "end users session login name": "end users session login name",
    "last name": "Last name",
    "first name": "First name",
    "none": "None",
    "email": "Email",
    "sms": "SMS",
    "contact email address": "Contact email address",
    "contact SMS number": "contact SMS number",
    "create ticket directly with Swisscom": "create ticket directly with Swisscom",
    "acceptMsg": "I agree to pay the ticket costs to Swisscom if the fault is not Swisscom's.",
    "xdsl_unknown": "Unknown",
    "xdsl_no_signal": "No signal",
    "xdsl_no_connection": "No connection",
    "xdsl_interuption": "Interruptions",
    "xdsl_performance": "Performance",
    "xdsl_undefined_line": "Undefined line",
    "fiber_no_signal": "No signal",
    "fiber_no_connection": "No connection",
    "fiber_interuption": "Interuptions",
    "fiber_performance": "Performance",
    "fiber_coord": "Coord",
    "fiber_bbcs_f_2nd_level_analyses": "2nd level analyses",
    "xdsl_unknown__descr": "The problem cannot be further specified.",
    "xdsl_no_signal__descr": "No DSL signal on the UP available. Testing is only carried out up to the UP, not in the building installation.",
    "xdsl_no_connection__descr": "No IP can be obtained (DHCP or PPPoE). Access data must be checked. With ADSL connections, VPI 8 and VCI 35 are absolutely necessary, otherwise registration is not possible.",
    "xdsl_interuption__descr": "Interruptions in the DSL signal or the connection.",
    "xdsl_performance__descr": "The connection on the UP offers significantly less speed than intended / Many errors on the UP (CRC / FEC) / packet loss, which is not caused by a busy upload.",
    "xdsl_undefined_line__descr": "The connection is in an undefined state.",
    "fiber_no_signal__descr": "No optical signal available.",
    "fiber_no_connection__descr": "No IP can be obtained (DHCP or PPPoE). Access data must be checked.",
    "fiber_interuption__descr": "Interruptions in the optical signal or the connection.",
    "fiber_performance__descr": "Connection offers significantly less speed than intended / many errors (CRC / FEC) / packet loss, which is not caused by a busy upload.",
    "fiber_coord__descr": "Coord",
    "fiber_bbcs_f_2nd_level_analyses__descr": "2nd level analysis is necessary.",
    "Your {worker_name} ticket '{subject}' has been successfully created.": "Your {worker_name} ticket '{subject}' has been successfully created."
  },
  "de": {
    "create BBCS ticket": "BBCS Ticket anlegen",
    "create": "Anlegen",
    "cancel": "Abbrechen",
    "close": "Schliessen",
    "end user data": "Endkunden Angaben",
    "phone": "Telefon",
    "building": "Gebäude",
    "user login": "Benutzer Login",
    "contact data": "Kontakt Angaben",
    "contact language": "Kontakt Sprache",
    "phone number": "Telefonnummer",
    "notify type": "Benachrichtigungsart",
    "issue details": "Problem Details",
    "error category": "Fehler Kategorie",
    "service error category": "Dienst Fehler Kategorie",
    "equipment": "Gerät",
    "problem date": "Problem Datum",
    "problem time": "Problem Zeit",
    "problem description": "Problem Beschreibung",
    "notify by": "Benachrichtigen mittels",
    "end users contact phone number": "Endkunden Kontakt Telefonnummer",
    "end users contact name": "Endkunden Kontaktname",
    "end users session login name": "Endkunden Sitzungs Login Name",
    "last name": "Nachname",
    "first name": "Vorname",
    "none": "keine",
    "email": "Email",
    "sms": "SMS",
    "contact email address": "Kontakt Email Adresse",
    "contact SMS number": "Kontakt SMS Nummer",
    "create ticket directly with Swisscom": "Ticket direkt bei der Swisscom anlegen",
    "acceptMsg": "Hiermit erkläre ich mich einverstanden, die Ticketkosten der Swisscom zu übernehmen falls der Fehler nicht bei der Swisscom liegt.",
    "xdsl_unknown": "unbekannt",
    "xdsl_no_signal": "kein Signal",
    "xdsl_no_connection": "keine Verbindung",
    "xdsl_interuption": "Unterbrüche",
    "xdsl_performance": "Performance",
    "xdsl_undefined_line": "undefinierter Anschluss",
    "fiber_no_signal": "kein Signal",
    "fiber_no_connection": "keine Verbindung",
    "fiber_interuption": "Unterbrüche",
    "fiber_performance": "Performance",
    "fiber_coord": "Coord",
    "fiber_bbcs_f_2nd_level_analyses": "2nd Level Analyse",
    "xdsl_unknown__descr": "Das Problem lässt sich nicht weiter spezifizieren.",
    "xdsl_no_signal__descr": "Kein DSL Signal am UP vorhanden. Geprüft wird nur bis zum UP, nicht in der Hausinstallation.",
    "xdsl_no_connection__descr": "Es kann keine IP bezogen werden (DHCP oder PPPoE). Zugangsdaten müssen geprüft werden. Bei ADSL Anschlüssen ist VPI 8 und VCI 35 zwingend nötig, sonst ist eine Anmeldung nicht möglich.",
    "xdsl_interuption__descr": "Unterbrüche des DSL Signals oder der Verbindung.",
    "xdsl_performance__descr": "Anschluss bietet am UP deutlich weniger Geschwindigkeit als vorgesehen / Viele Fehler am UP (CRC/FEC) / Packetloss, welcher nicht von ausgelastetem Upload verursacht wird.",
    "xdsl_undefined_line__descr": "Der Anschluss befindet sich in einem undefinierten Zustand.",
    "fiber_no_signal__descr": "Kein optisches Signal vorhanden.",
    "fiber_no_connection__descr": "Es kann keine IP bezogen werden (DHCP oder PPPoE). Zugangsdaten müssen geprüft werden.",
    "fiber_interuption__descr": "Unterbrüche des optischen Signals oder der Verbindung.",
    "fiber_performance__descr": "Anschluss bietet deutlich weniger Geschwindigkeit als vorgesehen / Viele Fehler (CRC/FEC) / Packetloss, welcher nicht von ausgelastetem Upload verursacht wird.",
    "fiber_coord__descr": "Coord",
    "fiber_bbcs_f_2nd_level_analyses__descr": "2nd Level Analyse ist notwendig.",
    "Your {worker_name} ticket '{subject}' has been successfully created.": "Ihr {worker_name} Ticket '{subject}' wurde erfolgreich angelegt."
  }
}
</i18n>
