<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("BBCS xDSL information") }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-dialog v-model="reconfigureDialog" width="400" persistent>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            text
            :disabled="
              loading || requesting || Object.entries(data).length === 0
            "
          >
            {{ $t("reconfigure") }}
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="titleBar" dark flat dense>
            {{ $t("atention") }}
          </v-toolbar>
          <v-card-text class="mt-4">
            {{ $t("warning text") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="reconfigureDialog = false" text>
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              @click="
                reconfigureLine();
                reconfigureDialog = false;
              "
              text
            >
              {{ $t("reconfigure") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="resyncDialog" width="400" persistent>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            text
            :disabled="
              loading || requesting || Object.entries(data).length === 0
            "
          >
            {{ $t("resync") }}
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="titleBar" dark flat dense>
            {{ $t("atention") }}
          </v-toolbar>
          <v-card-text class="mt-4">
            {{ $t("warning text") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="resyncDialog = false" text>{{ $t("cancel") }}</v-btn>
            <v-btn
              @click="
                resyncLine();
                resyncDialog = false;
              "
              text
            >
              {{ $t("resync") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="getXdslInfo"
            :disabled="loading"
            :loading="loading"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("refresh xDSL status") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="$emit('close')" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("close") }}</span>
      </v-tooltip>

      <template v-slot:extension v-if="Object.entries(data).length !== 0">
        <v-tabs v-model="tabIndex" show-arrows>
          <v-tab v-for="(tab, i) in tabs" :key="i">{{ tab.label }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-card-text v-if="loading" class="pt-4">...</v-card-text>
    <v-card-text v-else-if="Object.entries(data).length === 0" class="pt-4">
      {{ $t("could not fetch xDSL status") }}
    </v-card-text>
    <v-card-text v-else>
      <v-tabs-items v-model="tabIndex" class="my-4">
        <v-tab-item v-for="(tab, i) in tabs" :key="i">
          <div v-if="tab.type === 'table'">
            <div v-for="(value, i) in tab.content" :key="i">
              <xdslGeneral v-if="value.type === 'plain'" :element="value" />
              <xdslGeneral
                v-else-if="value.type === 'title'"
                :element="value"
                title
              />
              <xdslGeneral
                v-else-if="value.type === 'subtitle'"
                :element="value"
                subtitle
              />
              <xdslDivider v-else-if="value.type === 'divider'" />
              <xdslTrafficLight
                v-else-if="value.type === 'traffic_light'"
                :element="value"
              />
              <xdslTable v-else-if="value.type === 'table'" :element="value" />
              <p v-else class="red">{{ value }}</p>
            </div>
          </div>

          <div v-if="tab.name === 'charts_15min'" style="height: 800px">
            <xdslChartsFifteen
              v-for="(value, i) in tab.content"
              :key="i"
              :element="value"
            />
          </div>
          <div v-if="tab.name === 'charts_day'" style="height: 800px">
            <xdslChartsDaily
              v-for="(value, i) in tab.content"
              :key="i"
              :element="value"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import xdslGeneral from "./xdslinfo/xdslGeneral";
import xdslDivider from "./xdslinfo/xdslDivider";
import xdslTrafficLight from "./xdslinfo/xdslTrafficLight";
import xdslTable from "./xdslinfo/xdslTable";
import xdslChartsFifteen from "./xdslinfo/xdslChartsFifteen";
import xdslChartsDaily from "./xdslinfo/xdslChartsDaily";

export default {
  name: "BbcsXdslInfo",
  components: {
    xdslGeneral,
    xdslDivider,
    xdslTrafficLight,
    xdslTable,
    xdslChartsFifteen,
    xdslChartsDaily,
  },
  props: {
    productId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    tabIndex: null,
    loading: false,
    data: {},
    resyncDialog: false,
    reconfigureDialog: false,
    requesting: false,
  }),
  watch: {
    productId: "getXdslInfo",
  },
  computed: {
    tabs() {
      let tabs = [];
      if (this.data == null) return tabs;
      for (let index = 0; index < this.data.tabs.length; index++) {
        var tab = this.data.tabs[index];
        tab["content"] = this.data.content[this.data.tabs[index].name];
        tabs.push(tab);
      }
      return tabs;
    },
  },
  methods: {
    resyncLine() {
      var that = this;
      if (this.productId != null) {
        this.requesting = true;
        this.$http
          .get("services/bbcs/" + this.productId + "/xdslinfo?command=resync") //
          .then((response) => {
            that.$snotify.success(that.$i18n.t("resync started"));
          })
          .catch((err) => {
            if (err.status != 400) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function () {
            that.requesting = false;
          });
      }
    },
    reconfigureLine() {
      var that = this;
      if (this.productId != null) {
        this.requesting = true;
        this.$http
          .get("services/bbcs/" + this.productId + "/xdslinfo?command=reconfig") //
          .then((response) => {
            that.$snotify.success(that.$i18n.t("reconfiguration started"));
          })
          .catch((err) => {
            if (err.status != 400) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function () {
            that.requesting = false;
          });
      }
    },
    getXdslInfo() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/bbcs/" + this.productId + "/xdslinfo?charts=1") //
        .then((response) => {
          that.data = response.data;
        })
        .catch((err) => {
          if (err.status != 400) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted: function () {
    this.getXdslInfo();
  },
};
</script>

<i18n>
{
  "en": {
    "BBCS xDSL information": "BBCS xDSL information",
    "could not fetch xDSL status": "Could not fetch xDSL status",
    "refresh xDSL status": "Refresh xDSL status",
    "resync": "Resync line",
    "reconfigure": "Reconfigure Line",
    "close": "Close",
    "cancel": "Cancel",
    "atention": "Atention",
    "warning text": "The line will be offline for ca. 10 minutes for this process.",
    "resync started": "Resync started",
    "reconfiguration started": "Reconfiguration started"
  },
  "de": {
    "BBCS xDSL information": "BBCS xDSL Information",
    "could not fetch xDSL status": "Konnte xDSL Status nicht abrufen",
    "refresh xDSL status": "xDSL Status aktualisieren",
    "resync": "Resync line",
    "reconfigure": "Reconfigure Line",
    "close": "Schliessen",
    "cancel": "Abbrechen",
    "atention": "Achtung",
    "warning text": "Die Leitung wird für diesen Prozess für ca. 10 minuten offline gehen",
    "resync started": "Resync wurde gestartet",
    "reconfiguration started": "Reconfiguration wurde gestartet"
  }
}
</i18n>
