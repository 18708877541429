<template>
<div>
    <div v-if="element.title" class="pre-title headline d-flex justify-center">
      <span>{{ element.title }}</span>
    </div>
  <div class="full d-flex justify-space-between">
      <div
        v-if="element.label"
        class="first font-weight-bold flex-grow-0 flex-shrink-0 px-2 text-right"
      >{{ element.label }}</div>

    <div
      class="next flex-grow-1 px-2 d-flex justify-space-between"
      v-for="(el,i) in element.value"
      :key="i"
    >

    <table style="width:100%">
      <tr>
        <th v-for="(inner_el,j) in el.title" :key="j">{{ inner_el }}</th>
      </tr>
      <tr v-for="(inner_rows,k) in el.rows" :key="k">
        <td v-for="(row_el,g) in inner_rows" :key="g">{{ row_el }}</td>
      </tr>
    </table>
    </div>
  </div>
</div>
</template>

<style scoped>
.pre-title {
  margin-bottom: 10px;
}
.full {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
  min-width: fit-content;
}
.first {
  width: 200px;
}
.next {
  min-width: 150px;
}

.inner-next {
  min-width: 100px;
}

table {
  outline: 1px solid rgba(0, 0, 0, 0.12);
  margin: 5px 0px;
}
td {
  padding-left: 4px;
}
</style>

<script>
export default {
  name: "xdslGeneral",
  props: {
    element: {
      type: Object,
      required: true
    },
    title: {
      type: Boolean
    },
    subtitle: {
      type: Boolean
    }
  },
  data: () => ({
    debug: null
  })
};
</script>