/**
 * parse Aplix INSTADR attribute and return parsed object:
 * 
 * parseAddress("Lommisweg 12, 8048 Zürich) 
 * 
 * returns:
 * {
 *  zip: "8048",
 *  city: "Zürich",
 *  street: "Lomisweg",
 *  streetNumber: "12",
 *  building: "",
 * }
 * 
 * parseAddress("?, Liehof, 9565 Rothenhausen") // with building
 * 
 * returns:
 * {
 *  zip: "9565",
 *  city: "Rothenhausen",
 *  street: "",
 *  streetNumber: "",
 *  building: "Liehof",
 * }
 * 
 * @param {*} value - address string
 */

function parseZipCity(value) {
    var parsedAddr = {
        zip: "",
        city: ""
    };
    var addr = value.split(" ").map(function (a) {
        return a.trim();
    });

    if (addr.length === 1 && addr[0] === "?")
        parsedAddr.city = "";
    else if (addr.length > 1 && addr[0].match(/^\d+$/))
        parsedAddr = {
            zip: addr[0],
            city: addr.slice(1).join(" ")
        };
    else if (addr.length > 1 && addr[addr.length - 1].match(/^\d+$/))
        parsedAddr = {
            zip: addr[addr.length - 1],
            city: addr.slice(0, -1).join(" ")
        };
    else if (addr.length > 1)
        parsedAddr.city = addr.join(" ");
    else if (addr[0].match(/^\d+$/))
        parsedAddr.zip = addr[0];
    else
        parsedAddr.city = addr[0];

    return parsedAddr;
}

function parseStreetNumber(value) {
    var parsedAddr = {
        street: "",
        street_number: ""
    };
    var addr = value.split(" ").map(function (a) {
        return a.trim();
    });

    if (addr.length === 1 && addr[0] === "?")
        parsedAddr.street = "";
    else if (addr.length > 1 && addr[addr.length - 1].match(/^\d+.*$/))
        parsedAddr = {
            street: addr.slice(0, -1).join(" "),
            street_number: addr[addr.length - 1]
        };
    else if (addr.length > 1 && addr[0].match(/^\d+.*$/))
        parsedAddr = {
            street: addr.slice(1).join(" "),
            street_number: addr[0]
        };
    else
        parsedAddr.street = addr.join(" ");

    return parsedAddr;
}

function parseBuilding(value) {
    var parsedAddr = {
        building: "",
    };

    if (value == null || value === "?")
        parsedAddr.building = "";
    else
        parsedAddr.building = value;

    return parsedAddr;
}

function parseAddress(value) {
    var parsedAddr = {
        zip: "",
        city: "",
        street: "",
        street_number: "",
        building: "",
    };

    if (value) {
        var addr = value.split(",", 3).map(function (a) {
            return a.trim();
        });

        if (addr.length > 2) {
            // ?, Casa Grützel, 7432 Umrein
            parsedAddr = {
                ...parsedAddr,
                ...parseStreetNumber(addr[0]),
                ...parseBuilding(addr[1]),
                ...parseZipCity(addr[2]),
            };
        } else if (addr.length > 1) {
            parsedAddr = {
                ...parsedAddr,
                ...parseStreetNumber(addr[0]),
                ...parseZipCity(addr[1]),
            };
        } else if (addr.length === 1) {
            parsedAddr = {
                ...parsedAddr,
                ...parseZipCity(addr[0])
            };
        }
    }

    return parsedAddr;
}

export default parseAddress;
